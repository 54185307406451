import Lottie from "lottie-react";

import json from "./lottie.json";

const LottieLoading = () => (
  <div className="gif">
    <Lottie
      animationData={json}
      loop={true}
      style={{ width: 200, margin: "0 auto" }}
    />
  </div>
);

export default LottieLoading;
