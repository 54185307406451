import React from "react";
import LottieLoading from "./../LottieLoading/index";

const LoadingIndicator = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "fixed",
      minHeight: "-webkit-fill-available",
      top: 0,
      left: 0,
      background: "#00000073",
    }}
  >
    <div style={{ textAlign: "center" }}>
      <LottieLoading />
    </div>
  </div>
);

export default LoadingIndicator;
