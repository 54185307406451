import { client } from './axios';

const login = (body) => client.post(`/control-plane/auth/login`, body);
const fetchMe = () => client.post(`/control-plane/auth/me`, {});
const addUser = (body) => client.post(`/user/add`, body);
const fetchRoles = (body) => client.post(`/control-plane/auth/fetch-role`, body);
const createRole = (body) => client.post(`/control-plane/auth/create-role`, body);
const updateRole = (body) => client.post(`/control-plane/auth/update-role`, body);
const fetchServices = (body) => client.post(`/control-plane/auth/fetch-service`, body);
const createService = (body) => client.post(`/control-plane/auth/create-service`, body);
const updateService = (body) => client.post(`/control-plane/auth/update-service`, body);
const fetchPaths = (body) => client.post(`/control-plane/auth/fetch-path`, body);
const createPath = (body) => client.post(`/control-plane/auth/create-path`, body);
const updatePath = (body) => client.post(`/control-plane/auth/update-path`, body);
const fetchPolicies = (body) => client.post(`/control-plane/auth/fetch-policy`, body);
const createPolicy = (body) => client.post(`/control-plane/auth/create-policy`, body);
const updatePolicy = (body) => client.post(`/control-plane/auth/update-policy`, body);
const fetchUsers = (body) => client.post(`/control-plane/auth/fetch-user`, body);
const createUser = (body) => client.post(`/control-plane/auth/create-user`, body);
const updateUser = (body) => client.post(`/control-plane/auth/update-user`, body);
const fetchProxies = (params) => client.get(`/proxy-order/list`, { params });
const fetchDashboard = (params) => client.get(`/dashboard`, { params });
const fetchRevenue = (params) => client.get(`/dashboard/deposit`, { params });
const fetchDNS = (body) => client.post(`/controlplane.dns.v1.DNSBackOfficeService/FetchDNS`, body);
const addDNS = (body) =>
  client.post(`/controlplane.dns.v1.DNSBackOfficeService/CreateDNS`, body, { isIgnoreError: true });
const updateDNS = (body) =>
  client.post(`/controlplane.dns.v1.DNSBackOfficeService/UpdateDNS`, body, { isIgnoreError: true });
const fetchTelco = (body) => client.post(`/controlplane.telco.v1.BackofficeTelcoService/FetchTelco`, body);
const addTelco = (body) =>
  client.post(`/controlplane.telco.v1.BackofficeTelcoService/CreateTelco`, body, { isIgnoreError: true });
const updateTelco = (body) =>
  client.post(`/controlplane.telco.v1.BackofficeTelcoService/UpdateTelco`, body, { isIgnoreError: true });
const fetchLocation = (body) => client.post(`/controlplane.location.v1.BackofficeLocationService/FetchLocation`, body);
const addLocation = (body) =>
  client.post(`/controlplane.location.v1.BackofficeLocationService/CreateLocation`, body, { isIgnoreError: true });
const updateLocation = (body) =>
  client.post(`/controlplane.location.v1.BackofficeLocationService/UpdateLocation`, body, { isIgnoreError: true });
const fetchResidentialAccount = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/FetchResAccount`, body);
const addResidentialAccount = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/CreateResAccount`, body, {
    isIgnoreError: true,
  });
const updateResidentialAccount = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/UpdateResAccount`, body, {
    isIgnoreError: true,
  });
const fetchResidentialNode = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/FetchResNode`, body);
const addResidentialNode = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/CreateResNode`, body, { isIgnoreError: true });
const updateResidentialNode = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/UpdateResNode`, body, { isIgnoreError: true });
const fetchResidentialPort = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/FetchResPort`, body);
const updateResidentialPort = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/UpdateResPort`, body, { isIgnoreError: true });
const restartResidentialPort = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/RestartPort`, body);
const fetchResidentialDevice = (body) =>
  client.post(`/controlplane.residential.v1.BackofficeResidentialService/FetchResDevice`, body);
const fetchProducts = (body) => client.post(`/merchant.v1.MerchantService/ListProductBase`, body);
const createProduct = (body) => client.post(`/merchant.v1.MerchantService/CreateProductBase`, body);
const updateProduct = (body) => client.post(`/merchant.v1.MerchantService/UpdateProductBase`, body);
const fetchMerchants = (body) => client.post(`/merchant.v1.MerchantService/ListMerchant`, body);
const addMerchant = (body) => client.post(`/merchant.v1.MerchantService/AddMerchant`, body);
const updateMerchant = (body) => client.post(`/merchant.v1.MerchantService/UpdateMerchant`, body);
const fetchMerchantAdmins = (body) => client.post(`/merchant.v1.MerchantService/ListMerchantUser`, body);
const addMerchantAdmin = (body) => client.post(`/merchant.v1.MerchantService/AddMerchantAdmin`, body);
const updateMerchantAdmin = (body) => client.post(`/merchant.v1.MerchantService/UpdateUser`, body);

const algoService = {
  updateMerchantAdmin,
  fetchMerchants,
  addMerchant,
  updateMerchant,
  fetchMerchantAdmins,
  addMerchantAdmin,
  restartResidentialPort,
  fetchProducts,
  createProduct,
  updateProduct,
  fetchUsers,
  createUser,
  updateUser,
  fetchPaths,
  createPath,
  updatePath,
  fetchPolicies,
  createPolicy,
  updatePolicy,
  fetchServices,
  createService,
  updateService,
  createRole,
  updateRole,
  fetchMe,
  fetchResidentialDevice,
  fetchResidentialPort,
  updateResidentialPort,
  fetchResidentialNode,
  addResidentialNode,
  updateResidentialNode,
  fetchResidentialAccount,
  addResidentialAccount,
  updateResidentialAccount,
  login,
  addUser,
  fetchRoles,
  fetchProxies,
  fetchDashboard,
  fetchRevenue,
  fetchDNS,
  addDNS,
  updateDNS,
  fetchTelco,
  addTelco,
  updateTelco,
  fetchLocation,
  addLocation,
  updateLocation,
};

export default algoService;
