export const ActionType = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
};

const initState = {
  accessToken: null,
  isLoggedIn: false,
  profile: null,
};

const user = (state = initState, { type, data }) => {
  switch (type) {
    case ActionType.USER_LOGIN:
      return { ...state, ...data };
    case ActionType.USER_LOGOUT:
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default user;
