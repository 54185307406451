import { PERMISSIONS_PAGE } from './constants';

const isHasPermission = (permissions, listPath) => {
  if (Array.isArray(permissions)) return permissions.some((item) => listPath?.includes(item));
  return listPath?.includes(permissions);
};

const isHasPermissionPage = (path, listPath) => {
  const pagePermission = PERMISSIONS_PAGE[path] || [];
  return pagePermission.some((item) => isHasPermission(item, listPath));
};

export { isHasPermission, isHasPermissionPage };
